import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { animated, useTransition } from 'react-spring'
import Layout from '../components/layout/layout'
import { PostItem } from '../components/post-item'
import { MarkdownRemarkConnection, Site } from '../graphql-types'

type Props = {
  data: {
    allMarkdownRemark: MarkdownRemarkConnection
    site: Site
  }
  pageContext: {
    page: number
    limit: number
    skip: number
  }
  location: Location
}

const BlogPostList: FunctionComponent<Props> = props => {
  const { data } = props
  const { edges: posts } = data?.allMarkdownRemark

  const transitions = useTransition(posts, post => post.node.fields?.slug!, {
    from: { transform: 'translate3d(200px,0,0)', opacity: 0 },
    enter: { transform: 'translate3d(0,0px,0)', opacity: 1 },
    config: { duration: 300 },
    trail: 5,
  })

  return (
    <Layout>
      {transitions.map(({ item: { node }, props, key }) => {
        return (
          <animated.div key={key} style={props}>
            <PostItem path={`/posts/post-${new Date(node.frontmatter?.date).getTime()}`} node={node} />
          </animated.div>
        )
      })}
    </Layout>
  )
}

export default BlogPostList

export const query = graphql`
  query PostListQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }, filter: { fileAbsolutePath: { regex: "/blog/" } }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            date
            description
            tags
            title
          }
          fields {
            slug
          }
          fileAbsolutePath
          wordCount {
            words
          }
          timeToRead
        }
      }
    }
    dataYaml {
      createPageConfig {
        activeLayout
        posts {
          listPath
        }
      }
    }
  }
`
